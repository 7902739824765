import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  CircularProgress,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal
} from '@mui/material';

import { getUsers, deleteUser, userStatus } from 'src/store/api/user';
import { ActiveInactiveButton } from 'src/components/Button';
import BreadcrumbNavigator from 'src/components/BreadcrumbNavigator';
import { getSearchQueryParams, setSearchQueryParams, recordPerPage } from 'src/helpers/SearchHelper';
import { SearchInTable } from 'src/components/Table';
import ConfirmDeleteDialog from 'src/components/ConfirmDeleteDialog';
import ChangeStatusDialog from 'src/components/ChangeStatusDialog';
import API from 'src/config/api';

export default function User() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState({ status: false, id: null });
  const [changeStatusDialog, setChangeStatusDialog] = useState({ status: false, id: null, condition: null });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newCorporate, setNewCorporate] = useState('');
  const [error, setError] = useState('');
  const [listCorporates, setListCorporates] = useState([]); // Add this state for corporate list

  const { users } = useSelector((state) => ({ ...state.user }));

  useEffect(() => {
    const fetchData = async () => {
      const param = getSearchQueryParams(searchParams);
      setLoading(true);
      await dispatch(getUsers({ param }));
      setLoading(false);
    };
    fetchData();
  }, [searchParams, dispatch]);

  useEffect(() => {
    // Fetch list of corporates
    const fetchCorporates = async () => {
      try {
        const response = await API.get("/corporateList");
        setListCorporates(response.data.data);
      } catch (error) {
        console.error("Error fetching corporates:", error);
      }
    };
    fetchCorporates();
  }, []);

  const callDeleteFunc = (status, id) => {
    if (status === true) {
      dispatch(deleteUser({ id }));
    }
  };

  const changeStatusFunc = (status, id, condition) => {
    if (status === true) {
      const formValue = { id, status: condition };
      dispatch(userStatus({ formValue }));
    }
  };

  const handlePageChange = (event, onPage) => {
    const param = setSearchQueryParams(searchParams, onPage);
    navigate(`/user?${param}`);
  };

  const handleChangeRowsPerPage = (event) => {
    const param = setSearchQueryParams(searchParams, 0, event.target.value);
    navigate(`/user?${param}`);
  };

  const setSearchByParam = (param) => {
    navigate(`/user?${param}`);
  };

  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setNewCorporate(user.corporate ? user.corporate.id : '');
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedUser(null);
    setNewCorporate('');
    setError('');
  };

  const handleCorporateChange = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('user_id', selectedUser.id);
      formData.append('cor_id', newCorporate);

      const response = await API.post('/switchToCorUser', formData);
      
      if (response.data.success) {
        const param = getSearchQueryParams(searchParams);
        await dispatch(getUsers({ param }));
        handleCloseModal();
      } else {
        setError('Failed to change corporate');
      }
    } catch (err) {
      console.error("Error changing corporate:", err);
      setError('An error occurred while changing corporate');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <BreadcrumbNavigator
        currentPage="User List"
        rightButton={{ name: 'add user', link: '/user/add' }}
      />
      <Card>
        <SearchInTable searchByParam={setSearchByParam} />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Mobile Number</TableCell>
                    <TableCell align="left">Username</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Corp./Groups</TableCell>
                    <TableCell align="left">Active</TableCell>
                    <TableCell align="center">Report</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.data &&
                    users.data.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell component="th" scope="row">{jwtDecode(user.phone).phone}</TableCell>
                        <TableCell align="left">{user.username}</TableCell>
                        <TableCell align="left">{jwtDecode(user.email).email}</TableCell>
                        <TableCell align="left">
                          {user.roles &&
                            user.roles.map((role, index) => (
                              <ActiveInactiveButton
                                status={1}
                                key={index}
                              >
                                {role.name}
                              </ActiveInactiveButton>
                            ))}
                          <br />
                          {user.corporate && (
                            <ActiveInactiveButton status={1}>
                              {user.corporate.name}
                            </ActiveInactiveButton>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <ActiveInactiveButton
                            onClick={() => setChangeStatusDialog({ status: true, id: user.id, condition: user.status === 1 ? 0 : 1 })}
                            status={user.status}
                          >
                            {user.status === 1 ? 'Active' : 'Inactive'}
                          </ActiveInactiveButton>
                        </TableCell>
                        <TableCell align="center">{user.report_count}</TableCell>
                        <TableCell align="center">
                        <Button
                            variant="contained"
                            sx={{
                              backgroundColor: '#efeddc', // Very light yellow
                              color: 'black', // Ensure text is visible on light background
                              '&:hover': {
                                backgroundColor: '#FFF68F', // Slightly darker yellow on hover
                              }
                            }}
                            onClick={() => handleOpenModal(user)}
                          >
                            Update User Type
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={recordPerPage}
              component="div"
              count={users.total}
              rowsPerPage={users.per_page}
              page={users.current_page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Card>
      <ConfirmDeleteDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        confirmDialog={callDeleteFunc}
      />
      <ChangeStatusDialog
        openDialog={changeStatusDialog}
        setOpenDialog={setChangeStatusDialog}
        confirmDialog={changeStatusFunc}
      />
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="corporate-change-modal"
        aria-describedby="modal-to-change-corporate"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="corporate-change-modal" variant="h6" component="h2">
            Change Corporate
          </Typography>
          <Typography id="modal-to-change-corporate" sx={{ mt: 2 }}>
            Current Corporate: {selectedUser?.corporate?.name || 'N/A'}
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="new-corporate-label">New Corporate</InputLabel>
            <Select
              labelId="new-corporate-label"
              id="new-corporate-select"
              value={newCorporate}
              label="New Corporate"
              onChange={(e) => setNewCorporate(e.target.value)}
            >
              {listCorporates.map((corporate) => (
                <MenuItem key={corporate.id} value={corporate.id}>
                  {corporate.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{ width: '48%' }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCorporateChange}
              variant="contained"
              sx={{ width: '48%' }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Change Corporate'}
            </Button>
          </Box>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Box>
      </Modal>
    </Fragment>
  );
}