import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import { getUsers, deleteUser, userStatus } from 'src/store/api/user';
import { ActiveInactiveButton, LinkButton } from 'src/components/Button';
import ActionOptions from 'src/components/ActionOptions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ConfirmDeleteDialog from 'src/components/ConfirmDeleteDialog';
import ChangeStatusDialog from 'src/components/ChangeStatusDialog';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import { jwtDecode } from 'jwt-decode';
import BreadcrumbNavigator from 'src/components/BreadcrumbNavigator';
import { getSearchQueryParams, setSearchQueryParams, recordPerPage } from 'src/helpers/SearchHelper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Container,
  Button,
  Stack,
  Typography,
  Card,
  CircularProgress,
  Box,
  Modal,
  
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { SearchInTable } from 'src/components/Table';
import API from 'src/config/api';

export default function CorpUser({setHidden}) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newCorporate, setNewCorporate] = useState('');
  const profileData = localStorage.getItem('profile');
  const profileObject = JSON.parse(profileData);
  const [listCorporates, setListCorporates] = useState([]);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState({
    status: false,
    id: null
  });
  const [changeStatusDialog, setChangeStatusDialog] = useState({
    status: false,
    id: null,
    condition: null
  });

  const changeStatusFunc = (status, id, condition) => {
    if (status === true) {
      const formValue = {
        id: id,
        status: condition
      };
      dispatch(userStatus({ formValue }));
    }
  };

  const callDeleteFunc = (status, id) => {
    if (status === true) {
      dispatch(deleteUser({ id }));
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await API.get(`/user?per_page=10&order_by=latest&CorporateName=${profileObject.data.corporat_id}`);
      setUsers(response.data.data.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch users');
      setLoading(false);
    }
  };

  const fetchCorporateList = async () => {
    try {
      const response = await API.get("/corporateList");
      setListCorporates(response.data.data);
    } catch (err) {
      console.error("Failed to fetch corporate list:", err);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchCorporateList();
  }, []);

  

  

  const handleReportClick = (userId) => {
    localStorage.setItem('hiddenState', 'false');
      localStorage.setItem('selectedUserId', userId);
    navigate('/'); // Redirect to the home page
  };
console.log(listCorporates)
  return (
    <Typography sx={{maxWidth: '1880px', padding: '0 20px'}}>
      <BreadcrumbNavigator
        currentPage="Corporate User List"
        rightButton={{ name: 'add corp user', link: '/corp-user/add' }}
      />
      <Card className="mt-4 overflow-hidden">
        {loading ? (
          <Box className="flex justify-center items-center min-h-[300px]">
            <CircularProgress />
          </Box>
        ) : (
          <div className="overflow-x-auto">
            <TableContainer>
              <Table className="min-w-full">
                <TableHead>
                  <TableRow>
                    <TableCell className="whitespace-nowrap">Mobile Number</TableCell>
                    <TableCell className="whitespace-nowrap">Username</TableCell>
                    <TableCell className="whitespace-nowrap">Email</TableCell>
                    <TableCell className="whitespace-nowrap">Corp./Groups</TableCell>
                    <TableCell className="whitespace-nowrap">Active</TableCell>
                    <TableCell className="whitespace-nowrap text-center">Report</TableCell>
                   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell className="whitespace-nowrap">{jwtDecode(user.phone).phone}</TableCell>
                      <TableCell className="whitespace-nowrap">{user.username}</TableCell>
                      <TableCell className="whitespace-nowrap">{jwtDecode(user.email).email}</TableCell>
                      <TableCell>
                        <div className="flex flex-wrap gap-1">
                          {user.roles &&
                            user.roles.map((role, index) => (
                              <ActiveInactiveButton
                                status={1}
                                key={index}
                                className="text-xs"
                              >
                                {role.name}
                              </ActiveInactiveButton>
                            ))}
                        </div>
                        {user.corporate && (
                          <span className="text-sm text-gray-600 mt-1 block">
                            {user.corporate.name}
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        <ActiveInactiveButton
                            onClick={() => setChangeStatusDialog({ status: true, id: user.id })}
                            status={user.status}
                          >
                            {user.status === 1 ? 'Active' : 'Inactive'}
                          </ActiveInactiveButton>
                      </TableCell>
                      <TableCell 
              className="text-center cursor-pointer hover:bg-gray-100" 
              
            >
              <Button disabled={user.report_count === 0} sx={{
                              backgroundColor: '#efeddc', // Very light yellow
                              color: 'black', // Ensure text is visible on light background
                              '&:hover': {
                                backgroundColor: '#FFF68F', // Slightly darker yellow on hover
                              }
                            }}onClick={() => handleReportClick(user.id)}>
              {user.report_count}
              </Button>
             
            </TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Card>


      <ConfirmDeleteDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        confirmDialog={callDeleteFunc}
      />

      <ChangeStatusDialog
        openDialog={changeStatusDialog}
        setOpenDialog={setChangeStatusDialog}
        confirmDialog={changeStatusFunc}
      />
    </Typography>
  );
}